import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <header className="bg-gray-light">
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
      className="text-center"
    >
      <StaticImage
        src="../images/logo.png"
        width={250}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="大川市ロゴマーク"
      />
    </div>
  </header>
)

export default Header
